




















































































































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'VerticallAppBar',

  data() {
    return {
      userDrawer: false,
      notificationDrawer: false,
      searchDrawer: false,

      country: require('@/assets/images/flags/1x1/us.svg'),

      flags: [
        {
          country: require('@/assets/images/flags/1x1/us.svg'),
          lang: 'English'
        },
        {
          country: require('@/assets/images/flags/1x1/es.svg'),
          lang: 'Spanish'
        },
        {
          country: require('@/assets/images/flags/1x1/in.svg'),
          lang: 'India'
        }
      ]
    };
  },

  computed: {
    ...mapGetters(['getThemeMode']),
    ...mapGetters({ user: 'auth/getUser' })
  },

  methods: {
    ...mapActions(['changeVerticalSidebarDrawer', 'changeVerticalSidebarMini']),
    ...mapActions({ logout: 'auth/logout' }),

    signOut() {
      this.logout();
    },

    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();
    }
  }
});
